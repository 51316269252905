import { graphql } from 'gatsby';
import React from 'react';

import PageHandler from '@jpp/shared/PageHandler';
import { TPage } from 'common/types';

const ROOT_CLASSNAME = 'HomePage';

const HomePage: React.FunctionComponent<TPage> = (props) => (
  <PageHandler
    className={ROOT_CLASSNAME}
    {...props}
  />
);

export const pageQuery = graphql`
  query HomePage {
    contentfulPage(slug: { eq: "home" }) {
      ...ContentfulPage
    }
  }
`;

export default HomePage;
